const es = {
  'Tradicional Microbiology': 'Microbiología Tradicional',
  'Molecular Testing': 'Pruebas moleculares',
  'Chemical Testing': 'Pruebas químicas',
  Other: 'Otra',
  'Raw material': 'Materia prima',
  'Finished product': 'Producto terminado',
  'Semi elaborated': 'Semi elaborado',
  'Fish and fishery products': 'Pescado y productos pesqueros',
  'Materia prima': 'Materia prima',
  'Producto terminado': 'Producto terminado',
  'Semi elaborado': 'Semi elaborado',
  'Pescado y productos pesqueros': 'Pescado y productos pesqueros',
  Critical: 'Crítica',
  'Not critical': 'No crítica',
  Crítica: 'Crítica',
  'No crítica': 'No crítica',
  'zone 1': 'Zona 1',
  'zone 2': 'Zona 2',
  'zone 3': 'Zona 3',
  'zona 1': 'Zona 1',
  'zona 2': 'Zona 2',
  'zona 3': 'Zona 3',

  'Pre-sanitization': 'Pre-sanitización',
  'Post-sanitization': 'Post-sanitización',
  'In use': 'En uso',
  'With gloves': 'Con guantes',
  'Pre-sanitización': 'Pre-sanitización',
  'Post-sanitización': 'Post-sanitización',
  'En uso': 'En uso',
  'Con guantes': 'Con guantes',

  'Agua de Tratamiento': 'Agua de tratamiento',

  meses: {
    enero: 'Enero',
    febrero: 'Febrero',
    marzo: 'Marzo',
    abril: 'Abril',
    mayo: 'Mayo',
    junio: 'Junio',
    julio: 'Julio',
    agosto: 'Agosto',
    septiembre: 'Septiembre',
    octubre: 'Octubre',
    noviembre: 'Noviembre',
    diciembre: 'Diciembre',
  },
  submenu: {
    Workordersandrequests: 'Órdenes de trabajo y solicitudes',
    createneworder: 'Crear nueva orden',
    requestslist: 'Lista solicitudes',
    preworkorderlist: 'Lista pre-órdenes de trabajo',
    workorderlist: 'Lista órdenes de trabajo',
    completeworkorders: 'Órdenes de trabajo completadas',
    samples: 'Muestras',
    plantsandlocations: 'Plantas y locales',
    laboratorytest: 'Ensayos de laboratorio',
    staffmanager: 'Administrador de personal',
    resultreport: 'Informe de resultados',
    accountinformation: 'Información de la cuenta',
    subscriptions: 'Suscripciones',
    laboratories: 'Laboratorios',
    samplingprogram: 'Programa de muestreo',
    worksheet: 'Bitacora',
    worksheetMicro: 'Bitacora MB',

  },
  menu: {
    worksheet: 'Bitacora',
    worksheetMicro: 'Bitacora MB',
    analizando: 'Analizando datos',
    cargando: 'Cargando',
    WorkOrdersyRequests: 'Ordenes de trabajo y solicitudes',
    RequestsList: 'Lista solicitudes',
    Dismiss: 'Cerrar',
    crear_ot: 'Crear nueva orden',
    pre_ot: 'Lista pre-ordenes de trabajo',
    ot_muestra: 'Lista ordenes de trabajo',
    ot_com: 'Ordenes de trabajo completadas',
    lote_medio: 'Lote de reactivo',
    analisis: 'Subida de resultados PCR',
    ensayo: 'Ensayos',
    setting: 'Configuraciones',
    language: 'Lenguaje',
    salir: 'Cerrar sesión',
    aprende: 'Centro de aprendizaje',
    cerrar: 'Cerrar',
    samples: 'Muestras',
    Plants_locations: 'Plantas y locales',
    Laboratory_test: 'Ensayos de laboratorio',
    Users: 'Mantenedroe de usuarios',
    Analysts_supervisors: 'Analistas y supervisores',
    Result_report: 'Informe de resultados',
    Account_information: 'Información de la cuenta',
    staff: 'Administrador de personal',
    youplan: 'Tu plan',
    labs: 'Laboratorios',
    soli: 'Solicitudes',
    soli_ingresada: 'Solicitudes ingresadas',
    soli_coo: 'Coordinación de solicitudes',

  },
  analisis: {
    btn_confirm: 'Confirm',
    enter_pass: 'Por favor ingrese su contraseña para continuar',
    no_equipo_compatible: 'Esta bitacora no es compatible con el equipo seleccionado',
    medio: 'Medio',
    no_medio_and_equipo: 'Es necesario seleccionar un equipo y un ensayo para continuar',
    medioSelect: 'Seleccione Medio',
    no_datos_bitacora: 'No existe bitacora con estos datos',
    abandonar: '¿Deseas abandonar el sitio?',
    interno: 'Interno',
    Reviewyouranalysisresultsfor: 'Revise los resultados de su análisis para',
    medioanalisis: 'Análisis',
    selectanalisis: 'Selecciona Análisis',
    clase: 'Clase',
    Clase: 'Clase',
    well: 'Pocillo',
    reason: 'RAZÓN',
    Other: 'Otros',
    DETERIORADO: 'DETERIORADO',
    spoilage: 'DETERIORADO',
    nonspoilage: 'NO DETERIORADO',
    NODETERIORADO: 'NO DETERIORADO',
    deteriorado: 'Deteriorado',
    nodeteriorado: 'No deteriorado',
    no_deteriorado: 'No deteriorado',
    positivo: 'Positivo',
    negativo: 'Negativo',
    inhibido: 'Inhibido',
    vacio: 'Vacio',
    zonagris: 'Zona gris',
    microorganismonoregistrado: 'Microorganismo no registrado',
    lechesyproductoslacteos: 'Leches y productos lacteos',
    heladosymezclasparahelados: 'Helados y mezclas para helados',
    productosgrasos: 'Productos grasos',
    caldossopascremasymezclasdeshidratadas: 'Caldos, sopas, cremas y mezclas deshidratadas',
    productoselaboradosapartirdecereales: 'Productos elaborados a partir de cereales',
    azucaresymiel: 'Azúcares y miel',
    productosdeconfiteria: 'Productos de confitería',
    productosdepanaderiaypasteleria: 'Productos de panaderia y pasteleria',
    alimentosdeusoinfantil: 'Alimentos de uso infantil',
    carnesyproductoscarneos: 'Carnes y productos carneos',
    pescadosyproductosdelapesca: 'Pescados y productos de la pesca',
    huevosyovoproductos: 'Huevos y ovoproductos',
    salsasaderezosespeciasycondimentos: 'Salsas, aderezos, especias y condimentos',
    comidasyplatospreparados: 'Comidas  y platos preparados',
    frutasyverduras: 'Frutas y verduras',
    bebidasyaguas: 'Bebidas y aguas',
    estimulantesyfruitivos: 'Estimulantes y fruitivos',
    conservas: 'Conservas',
    noaplica: 'No aplica',

    agua: 'Agua',
    aguas: 'Agua',
    alimentos: 'Alimentos',
    ambientes: 'Ambientes',
    ambiente: 'Ambientes',
    manipuladores: 'Manipuladores',
    superficies: 'Superficies',
    controlpositivo: 'Control positivo',
    controlnegativo: 'Control negativo',

    water: 'Agua',
    foods: 'Alimentos',
    environments: 'Ambientes',
    handlers: 'Manipuladores',
    surfaces: 'Superficies',
    positivecontrol: 'Control positivo',
    negativecontrol: 'Control negativo',

    toolp: 'Seleccione tantos pocillos como desee y, a continuación, asígneles automáticamente determinados parámetros, como la matriz, la categoría de alimentos y el estado de la muestra.',
    analizar: 'Analizar',
    txt_1: 'Analice los resultados de su PCR',
    txt_2: 'Cargue su archivo sin procesar de PCR para una interpretación automática de los resultados',
    txt_3: 'Configura tu ensayo',
    txt_4: 'Configura tus muestras',
    txt_5: 'Introducir muestras manualmente',
    txt_6: 'Analizar una orden de trabajo',
    matriz: 'Matriz',
    sub_matriz: 'Sub-matriz',
    sube: 'Sube tu archivo de PCR y analicemos tus resultados!',
    error: 'Error al leer la estructura del archivo seleccionado',
    mn: 'Muestra no encontrada',
    m: 'Muestra encontrada',
    formato_invalido: 'Formato de archivo invalido, Ingrese un archivo con la extensión',
    no_datos: 'No se encontró datos con la OT',
    no_muestra: 'No hay muestras con algún kit Steriplex en la OT',

    bienvenido: 'Bienvenido',
    analiza: 'Analiza tus resultados de',
    selecciona_equipo: 'Selecciona tu prueba PCR',
    carga: 'Sube tu archivo PCR',
    Selecciona: 'Selecciona tu equipo PCR',
    selecciona_programa: 'Selecciona el programa',
    ingresa_numero_bitacora: 'Ingresa el número de bitacora',
    calcular_archivos: 'Calcular archivos',
    caracteres_alfanumericos: 'Alfanuméricos 1 a 300 caracteres',
    ingresa: 'Escriba el número de orden de trabajo',
    categoria: 'Categoría',
    revisar_ot: 'Buscar',
    analizar_resultados: 'Analizar resultados',
    forma_manual: 'Ingresa tus muestras de forma manual',
    acciones: 'Acciones',
    aplicar_matriz: 'Aplicar matriz',
    estado: 'Estado',

    codigo_muestra: 'Código muestra',
    estado_muestra: 'Estado de la muestra',
    ingresa_codigo: 'Ingresa código',
    tipo_muestra: 'Tipo de muestra',
    seleccionar_matriz: 'Seleccionar matriz',
    seleccionar_kit: 'Seleccionar categoría',
    seleccionar_status: 'Seleccionar estado',
    seleccionar_ensayo: 'Seleccionar ensayo',
    seleccionar_mix: 'Seleccionar mix',
    seleccionar_medio: 'Seleccionar medio',
    seleccionar_sample_status: 'Seleccionar estado',
    agregar_pocillos: 'Añadir pocillos',

    Resultados_analisis: 'Resultados de tus análisis',

    muestras_publicadas: 'Muestras publicadas',
    muestras_publicadas_txt1: 'con exito',
    muestras_publicadas_txt2: 'puedes consultarlas en ',

    sin_ingresar_muestra: 'No se ha ingresado muestras de',
    sin_ingresar_muestra_txt1: 'para publicar',

    el_param: 'El parámetro',
    el_param_txt1: 'de la muestra',
    el_param_txt2: 'no se publicó',

    la_muestra: 'La muestra',
    la_muestra_txt1: 'no se publicó por tener',
    la_muestra_txt2: 'favor de volver a realizar el PCR.',

    pocillo: 'Pocillo',
    muestra: 'ID Muestra',
    descripcion: 'Descripción muestra',
    sample_status: 'Estado de la muestra',
    origen: 'Origen',
    resultado: 'Resultado',

    otro: 'Otro',

    generar_pdf: 'Generar PDF',
    not_found_pdf: 'No se encontró PDF',
    publicar: 'Publicar',
    salir: 'Salir',
    publicando_bitacora: 'Publicando bitacora',

    m_resultados: 'Muestra con resultados',
    m_resultados_txt1: 'La muestra ya se encuentra con resultados y publicada.',
    m_resultado_txt2: '¿Deseas publicar de nuevo?',

    confirmar_publicar_resultados: '¿Seguro quieres publicar los resultados?',
    aceptar: 'Aceptar',
    cancelar: 'Cancelar',
    publicar_resultados: 'Publicar resultados',

    confirmar_regresar_analisis: '¿Seguro quieres regresar a analisis de resultados?',

    // modal analisis

    _ot: 'Orden de Trabajo',
    la_ot_txt1: 'contiene',
    la_ot_muestra_txt2: 'muestras',
    la_ot_muestra_txt3: 'muestra',
    seleccionar_kit_pcr: 'Seleccionar kit de PCR',
    kit_pcr: 'Kit de PCR',
    Selectlaboratorytest: 'Seleccione un ensayo de laboratorio',
    c_negativo: 'Control negativo',
    c_negativo_simbolo: 'Control -',
    c_positivo: 'Control positivo',
    c_positivo_simbolo: 'Control +',
    cePositivo: 'Control de extraccion positivo',
    ceNegativo: 'Control de extraccion negativo',
    cMedio: 'Control medio positivo',
    cnMedio: 'Control medio negativo',
    noResults: 'No existen resultados para estos parametros',
    repetirReaccion: 'Error de perfil, repetir reacción',
    reaccionGris: 'Zona gris, repetir reacción',

    limpiar: 'Limpiar',
    txt1: 'No olvides verificar que cada pocilo asignado a tu muestra esté asociado a su ensayo correspondiente, ni olvides incluir tus controles.',
    guardar: 'Guardar',

    // kit ensayos
    aerobiosmesofilo: 'Aerobios mesofilo',
    mesofilosanaerobios: 'Mesofilos anaerobios',
    termofilos: 'Termófilos',
    acidolacticas: 'Acido lácticas',
    termoacidofilas: 'Termoacidofilas',
    pool: 'Pool',
    lactobacillusspp: 'Lactobacillus spp',
    bitacora: 'Analizar por Bitacora',
    analisis_sin_muestra: 'Analizar sin muestras',
    ensayo: 'Ensayo',
    control: 'Control',
    sin_bitacora: 'Sin bitacora',
    mix: 'Mix',

    selecciona_categoria: 'Selecciona una categoria',

    // validacion controles
    confirmar_validacion_controles: 'Entendido',
    next_controls_need_to_be_added: 'Necesita agregar los siguientes controles',
    not_samples: 'No hay muestras',
    no_samples_asigned: 'No hay muestras asignadas',
    incomplete_controls: 'Controles incompletos',
    samples_medium_without_assays: 'Muestras con medio sin ensayos',

    has_controls_with_assays_not_used: 'Tiene controles con ensayos no utilizados',

    continue_advance_deleted: 'Si continua  se eliminara el avance',
    are_you_sure_overwrite_them: 'Esta bitacora ya tiene muestras publicadas. ¿Desea sobreescribirlas?',
    are_you_sure_overwrite_sample: 'Esta muestra ya esta publicada. <br>¿Desea sobreescribirla?',
    yes_overwrite: 'Si, sobrescribir',

    // errores searchBitacora
    not_found_bitacora: 'No se encontró bitacora',
    some_assays_cant_use_equipment: 'Algunos ensayos no pueden usar este equipo',
    not_found_canales: 'No se encontraron canales',
    some_assays_has_not_software_to_this_equipment: 'Algunos ensayos no tienen software para este equipo',

    no_keep_current: 'No, conservar actuales',
    reproceso: 'Reprocesar',
    must_select: 'Debe seleccionar',
    files: 'archivos',
    no_puede_cargar_mas_de: 'No se puede cargar mas de',
    archivos_invalidos: 'Archivos invalidos',
    error_eliminar_archivo: 'Error al eliminar archivo',
    has_invalid_file: 'Tiene archivos invalidos',
    warning: 'Advertencia',
    ningun_pocillo_seleccionado: 'Advertencia',
    no_muestras_bitacora: 'No hay muestras en la bitacora',
    bitacora_publicada: 'Bitacora publicada',
    bitacora_publicada_parcialmente: 'Bitacora se publicado parcialmente',
    ocurrio_error_publicar: 'Ocurrio un error al publicar',
    tipo: 'Tipo',
    global: 'Global',
    range1: 'Rango 1',
    range2: 'Rango 2',
    an_error_occurred: 'Ocurrio un error, por favor contacta a TI.',
    loading: 'Cargando...',
    directa: 'Directa',
    buscando_bitacora: 'Buscando bitacora',
    verificando_pocillos: 'Verificando pocillos',
    validando_archivos_subidos: 'Validando Archivos Subidos',
    resultados_pocillos_empty: 'No se encontraron resultados para los pocillos',
    error_get_results_interpretacion: 'Error al obtener resultados para interpretacion',
    interpretando: 'Interpretando resultados',
    no_files_required: 'Archivos requeridos',
    no_all_files_uploaded: 'No se han cargado todos los archivos requeridos',

    tipo_muestra: 'Tipo de muestra',

    // warnings
    EMPTY_ENSAYO_MUESTRA: 'Ensayo de la muestra vacio.',
    NOT_FOUND_ASSAY: 'No se encontro el ensayo.',
    IS_PUBLISH: 'Muestra se encuentra publicada.',
    NOT_FOUND_SAMPLE_BITACORA: 'No se encontro la muestra en la bitacora.',
    CANNOT_FIND_PARAMETERS_MIX: 'No se encontraron la parametros del mix.',
    CANNOT_DELETED_RESULTS_PREVIOUS_ASSAY_STERIPLEX: 'No se pudo eliminar los resultados previos.',
    CANNOT_DELETED_PARAMETERS_ASSAY_STERIPLEX: 'No se pudo eliminar los parametros previos.',
    CANNOT_DELETED_PRERESULTS_ASSAY_STERIPLEX: 'No se pudo eliminar los resultados previos del ensayo.',
    RESULTADOS_EMPTY: 'Sin resultados.',
    NOT_FOUND_PARAMETERS_ASSAY: 'No se encontraron los parametros del ensayo.',
    NOT_FOUND_PARAMETER: 'No se encontro el parametro',
    NOT_ASING_PARAMETER_CUMPLE: 'No se pudo asignar el resultado al parametro ',
    CANNOT_REACTIVE_PARAM_MUESTRA_OT: 'No se pudo activar el parametro ',
    CANNOT_CREATE_PARAM_MUESTRA_OT: 'No se pudo crear el parametro ',
    CANNOT_DELETED_PRERESULT: 'No se pudo eliminar el resultado previo del parametro ',
    CANNOT_CREATE_PRERESULT: 'No se pudo crear el pre-resultado al parametro',
    CANNOT_FIND_PRERESULTS_COMBINATION: 'No se encontro la combinacion de resultados al parametro',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_ERROR: 'No se pudo actualizaar la combinacion de resultados al parametro',
    INCONGRUENT_COMBINATION_RESULTS: 'Combinacion de resultados incongruente al parametro',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R1: 'No se pudo asignar reproceso rango 1 al parametro',
    ONLY_REPROCESS_R1: 'Solo reproceso rango 1 al parametro',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R2: 'No se pudo asignar reproceso rango 2 al parametro',
    ONLY_REPROCESS_R2: 'Solo reproceso rango 2 al parametro',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_ALL: 'No se pudo mandar a  reproceso todos los rangos del parametro',
    REPROCESS_ALL: 'Reproceso de toda la muestra del parametro',
    REPROCESS_RANGES: 'Reproceso de los rangos del parametro',
    REPROCESS_GLOBAL: 'Reproceso de la muestra global del parametro',
    WAITING_RESULTS_OTHER_RANGES: 'Esperando resultados de los demas rangos del parametro',
    CANNOT_UPDATED_NO_COMBINATION_REPROCESO: 'No se pudo actualizar a reproceso el parametro',
    REPROCESS_PARAMETER: 'Reproceso al parametro',
    CANNOT_DESTROY_RESULT_PREVIOUS: 'No se pudieron eliminar los resultados previos del parametro',
    CANNOT_PUBLISH_POSITIVE_RESULT: 'No tienes permiso de publica resultado positivo del parametro',
    CANNOT_CREATE_RESULT: 'No se pudo guardar el resultado del parametro',
    NOT_FOUND_CONTROL_INTERNO_ASSAY: 'No se encontro el control interno del ensayo',
    NOT_FOUND_MIXES_ASSAY: 'No se encontraron los mix del ensayo',
    CANNOT_UPDATE_PRERESULT_MIXES: 'No se pudo actualizar el resultado del ensayo',
    CANNOT_UPDATE_TO_PUBLISH_RESULTS_ENSAYO: 'No se pudo publicar el resultado del ensayo',
    CANNOT_UPDATE_END_PUBLICATION_ENSAYO_MUESTRA: 'No se pudo publicar el ensayo',
    CANNOT_UPDATE_ESTATUS_BITACORA: 'No se pudo actualizar el estado de la bitacora',

    results_parametros_no_registrados: 'Los resultados no tienen parámetros registrado en el sistema',
    muestra: 'Muestra',
    ic_error: 'Error en la reacción',
    ic_ok: 'Control Interno cumple con paŕametros establecidos',
    ic_inhibido: 'Pocillo Inhibido o vacío',
    param_no_detectado: 'No detectado en la muestra (valor de Cq inferior al límite de detección)',
    param_presencia_sobrelimite: 'Presencia en la muestra con un valor de Cq por sobre el límite de cuantificación',
    param_no_cuantificable: 'Presente en la muestra, pero no puede ser cuantificada',
    param_presencia: 'Presencia',
    alto_riesgo: 'Alto Riesgo',
    bajo_riesgo: 'Bajo Riesgo',

    // categorias matriz superficie
    zona_1: 'Zona 1',
    zona_2: 'Zona 2',
    zona_3: 'Zona 3',
    critica: 'Crítica',
    no_critica: 'No Crítica',

    // categorias matriz manipuladores y ambientes
    pre_sanitizacion: 'Pre-sanitización',
    post_sanitizacion: 'Post-sanitización',
    en_uso: 'En Uso',
    con_guantes: 'Con Guantes',

    // categorias matriz agua
    agua_de_tratamiento: 'Agua de Tratamiento',
    agua_de_saneo: 'Agua de Saneo',
    agua_de_proceso: 'Agua de Proceso',
  },
  ensayos: {
    descRequired: 'Las descripciones de los controles son obligatorias',
    NoControles: 'No existen controles',
    NoControlesNeg: 'Es necesario al menos un control negativo',
    NoControlesPos: 'Es necesario al menos un control positivo',
    UmbralRequired: 'Es necesario configurar umbrales para todos los controles',
    NoControls: 'Sin controles',
    controlListOptions: 'Opciones',
    controlListUmbrals: 'Umbrales',
    controlListDesc: 'Descripcion',
    noUmbrals: 'Sin umbrales',
    addDescMuestras: 'Agregar descripcion de la muestra',
    addControls: 'Agregar Control',
    selectControls: 'Seleccione control',
    PleasefillControles: 'Por favor configure los controles correspondientes',
    no_sub_options: 'Sin sub opciones',
    no_results: 'No se han encontrado resultados...',
    configCountResults: 'Config. resultados de tipo cuantitativo',
    definirEnsayos: 'Definir los Rangos del ensayo',
    rangosLeyenda: 'Definir los rangos que tendrá el ensayo',
    rango1: 'Rango 1',
    rango2: 'Rango 2',
    Rowsperpage: 'Filas por página:',
    viewunitsofmeasure: 'Ver unidades de medida',
    idioma: 'Idioma',
    Addconditional: 'Agregar condicional',
    Assignunitsofmeasure: 'Asignar unidades de medida',
    Testname: 'Nombre del ensayo',
    Testcategory: 'Categoría del ensayo',
    Status: 'Estado',
    Setuptestparameters: 'Configurar parámetros de ensayo',
    Setupandaddrelevant: 'Configurar y agregar información relevante sobre los parámetros de detección de esta ensayo',
    Basicinformation: 'Información básica',
    UmbralesInformation: 'Configuracion de controles',
    Pleasefill: 'Complete la información solicitada para crear su ensayo',
    Classesoutofspec: 'Clases fuera de especificación.',
    Selectcategory: 'Seleccionar categoría',
    Addcondition: 'Añadir condición',
    Informationrequired: 'Información requerida',
    warning: 'Advertencia',
    ConfigurarEnsayo: 'Configuración de ensayos de laboratorio',
    Matrices: 'Matrices',
    clasesnocumplen: 'Clases que no cumplen',
    Clases: 'Clases',
    Addthresholds: 'Agregar umbrales',
    cusromparam: 'Editar paramteros',
    labs: 'Laboratorios',
    costo: 'Costo',
    cod_ensayo: 'Código ensayo',
    tiempo_result: 'Tiempo para obtener resultados (hr)',
    tiempo_incub: 'Tiempo de incubacion (hr)',
    activite: 'Activar ensayos TAAG',
    active_lab: 'Activar los ensayos de laboratorio TAAG',
    txt_kit: 'Agregue ensayos de laboratorio compatibles con los kits de PCR en tiempo real de TAAG Genetics',
    add: 'Agregar',
    Addparameter: 'Agregar parámetro',
    bajo: 'Bajo',
    alto: 'Alto',
    alto_riesgo: 'Alto Riesgo',
    bajo_riesgo: 'Bajo Riesgo',
    pathogeno: 'Patógeno',
    indicador: 'Indicador',
    deteriorante: 'Deteriorante',
    cerrar: 'Cerrar',
    // modal- param condition
    txt_param_condition: 'Condiciones de los parámetros',
    t_param: 'Parámetro',
    classifitacion: 'Clasificación',
    RiskLevel: 'Nivel de riesgo',
    you_param: 'Tus parámetros',
    add_param: 'Nombre del parámetro',

    // modal-tipo ensayo

    txt_title: 'Configurar parámetros',
    test: 'Ensayo',
    tezt_category: 'Categoría del ensayo',
    tipo_test: 'escriba el nombre del ensayo',
    category: 'Seleccionar categoría del ensayo',

    // modal-configuracion recuento

    title: 'Configuración de resultados de tipo de conteo',
    title2: 'Seleccione la notación numérica en la que se informarán sus resultados de tipo de conteo',

    // txt- tooltpis
    txt_namerepot: 'Este nombre aparecerá en el informe.',
    txt_t_ensayo: 'Aquí selecciona o crea un tipo de ensayo, es decir, biología molecular, ensayos tradicionales, etc.',
    txt_desc: 'Esta descripción aparecerá en el informe.',
    txt_metho: 'Esta metodología aparecerá en el informe.',
    txt_param: 'Esto es lo que detectará su ensayo, por ejemplo, Staphylococcus aureus, niveles de cobre, coliformes totales, etc. Puede agregar tantos parámetros como desee de acuerdo con las limitaciones de su prueba.',
    txt_t_tecnica: 'Si seleccionó microbiología tradicional, ensayos moleculares o pruebas rápidas, nos gustaría que agregue información adicional sobre sus parámetros, como el nivel de riesgo o la clasificación, para que podamos refinar nuestros algoritmos para un análisis óptimo.',
    txt_result: 'Así es como informará sus resultados.',

    ensayos_lab: 'Ensayos de laboratorio',
    crear_edita: 'Crear, editar y gestionar ensayos de laboratorio.',
    buscar: 'Buscar',
    crear_nuevo_ensayo: 'Crear nuevo ensayo',
    mantebedor: 'Mantenedor de matrices',
    resumen: 'Resumen de ensayos',
    ningun_registro: 'No se encontró ningún registro',
    r_pagina: 'Registro por pagina',
    inactivo: 'Inactivo',
    activo: 'Activo',
    detalle: 'Ver detalle',
    mantenedor: 'Mantenedor de parametros',
    crear_param: 'Crear parametros',
    guardar: 'Guardar',
    guardar_ensayo: 'Guardar ensayo',
    param_manager: 'Administrador de parámetros',
    nombre: 'Nombre del ensayo',
    tipo: 'Tipo',
    param_mtz: 'Parámetros y matrices',
    option: 'Opciones',
    creado: 'Fecha de creación',
    clasif: 'Clasificación',
    rules_txt0: 'Nombre de ensayo es requerido',
    rules_incub: 'El tiempo de incubacion no puede ser mayor al tiempo de resultado',

    rules_txt1: 'Tipo de ensayo es requerido',
    rules_txt2: 'Tipo metodología es requerido ',
    rules_txt3: 'Sitio análisis es requerido',

    rules_txt4: 'El producto es requerido',
    rules_txt5: 'Tienes que seleccionar al menos un parámetro. Si no hay parámetros disponibles en la lista desplegable, agréguelos en el administrador de parámetros.',
    rules_txt6: 'El tipo resultado es requerido',
    rules_txt7: 'El tipo de kit es requerido ',
    rules_txt8: 'La descripción del ensayo es requerida ',
    rules_txt9: 'La metodología es requerida ',
    rules_txt10: 'Debes seleccionar una empresa',
    rules_txt11: 'Tiempo resultado es requerido ',
    rules_txt12: 'Tipo técnica es requerido',
    rules_txt13: 'El nombre del ensayo es requerido',

    rules_txt14: 'Umbral es requerido',
    rules_txt15: 'Tipo muestra es requerido',
    rules_txt_condicionparam: 'Selecciona parámetros y condiciones',
    rules_txt_condicionparamrangos: 'Selecciona al menos un rango para cada parametro',

    // modal
    param_matrices: 'Parámetros y matrices',
    matrices: 'Matrices',
    param: 'Parámetros',
    editar: 'Editar',
    cancelar: 'Cancelar',
    opciones: 'Opciones',

    // cierre modal

    agregar_ensayo: 'Agrega un nuevo ensayo de laboratorio',
    nombre_ensayo: 'Nombre del ensayo',
    estado: 'Estado del ensayo',
    tipo_ensayo: 'Tipo de ensayo',
    Name_tipo_ensayo: 'Nombre de tipo de ensayo',
    info_tecnicas: 'Información técnicas sobre tu ensayo',

    clasif_ensayo: 'Clasificación del ensayo',
    desc: 'Descripción (máx. 200 caracteres)',
    testnamea: 'Nombre de la muestra',
    activado: 'Activar',
    metodologia: 'Metodología',
    txt1: 'No se encontro',
    txt2: 'Presiona enter para crear.',
    tipo_tecnica: 'Especificación parámetros',
    tipo_resultado: 'Tipo de resultado',
    asignacion: 'Asignar matrices',
    select: 'Seleccione el tipo de muestras/matrices que se analizarán con este ensayo',
    unidades: 'Tipo unidad de medida',
    thparam: 'Parámetros',
    thumbral: 'Umbral',
    thunidad: 'Unidades',

    definir: 'Definir umbrales de detección y unidades de medida',
    selectumbrales: 'Seleccione el tipo de muestras/matrices que se analizarán con este ensayo',
    umbral: 'Escriba el valor de umbral',
    unidad_medida: 'Unidades de medida',
    alimento: 'Alimentos',
    superficies: 'Superficies',
    manipuladores: 'Manipuladores',
    aguas: 'Aguas',
    ambientes: 'Ambientes',
    txt3: 'No aplican umbrales para el tipo resultado "Ausencia / Presencia"',

    PA: 'Presencia Ausencia',
    RTO: 'Recuento',
    Ext: 'Exeternalizado',
    SC: 'Semi Cuantificación',

    rules_txt_condicion: 'Selecciona nivel y clasificación',
    update_succes: 'Actualizado correctamente',
    save_succes: 'Guardado exitosamente',
    selectedmatriz: 'Selecciona una matriz',
    delete_param: 'Parámetro eliminado',
    save_param: 'Parámetro guardado',
    // modal eliminar ensayo
    delete_title: 'Advertencia',
    delete_cuestion: '¿Estás seguro de que quieres eliminar este ensayo \n',
    delete_cuestion2: 'Esto no se puede deshacer.',
    activekits: 'Activar kits Taag',
    listakits: 'Lista de kits',

    // definir umbrales
    umbralesalimento: 'Umbrales no definidos en alimento',
    umbralessuperficie: 'Umbrales no definidos en superficies',
    umbralesmanipuladores: 'Umbrales no definidos en manipuladores',
    umbralesagua: 'Umbrales no definidos en agua',
    umbralesambiente: 'Umbrales no definidos en ambiente',

    item_node: {
      'Raw material': 'Materia prima',
      'Finished product': 'Producto terminado',
      'Semi elaborated': 'Semi elaborado',
    },
    validacion_rangos: 'El rango A es mayor al rango B',
    agregar_rango: 'Agregar rangos',
    // opciones
    editar_ensayo: 'Editar ensyayo',
    eliminar_ensayo: 'Eliminar ensayo',
  },
  matrices: {
    Alimento: 'Alimento',
    Alimentos: 'Alimento',
    Aguas: 'Agua',
    Agua: 'Agua',
    Superficies: 'Superficie',
    Manipuladores: 'Manipuladores',
    Ambientes: 'Ambiente',
  },
  modal_tipo_ensayo: {
    TradicionalMicrobiology: 'Microbiología tradicional',
    MolecularTesting: 'Análisis molecular',
    RapidTesting: 'Prueba rápida',
    ChemicalTesting: 'Análisis químico',
    Other: 'Otros',
  },
  tipoResul: {
    PresenceAbsence: 'Presencia Ausencia',
    Count: 'Cuantitativo',
    Exeternalized: 'Abierto',
    SemiCuantificación: 'Semi Cuantificación',
  },
  submatriz: {
    MateriaPrima: 'Materia prima',
    ProductoTerminado: 'Producto terminado',
    SemiElaborado: 'Semi elaborado',
    zona1: 'zona 1',
    zona2: 'zona 2',
    zona3: 'zona 3',
    critica: 'Crítica',
    nocritica: 'No crítica',
    presanitizacion: 'Pre-sanitización',
    postsanitizacion: 'Post-sanitización',
    enuso: 'En uso',
    guante: 'Con guantes',
  },
  solicitudes: {
    fechaasignado: 'Fecha asignado',
    Horainicio: 'Hora inicio',
    Horatermino: 'Hora termino',
    Success: 'Exito',
    Eventoeliminadocorrectamente: 'Evento eliminado correctamente',
    Advertencia: 'Advertencia',
    Deseaeliminarestasolicitud: '¿Desea eliminar este evento',
    aceptar: 'Aceptar',
    Max200characters: '200 caracteres como máximo',
    fecha: 'Fecha',
    horainicio: 'Hora inicio',
    horatermino: 'Hora termino',
    Asunto: 'Asunto',
    Descripcion: 'Descripción',
    Eliminar: 'Eliminar',
    Editar: 'Editar',
    Ingresarcomentarionomayora200caracteres: 'Ingresar comentario no mayor a 200 caracteres',
    EditarEvento: 'Editar evento',
    success: 'Exito',
    success1: 'Los cambios se hicieron correctamente',
    mostrar: 'Mostrar solicitudes',
    ocultar: 'Ocultar solicitudes',
    ingresada: 'Solicitudes ingresadas',
    cliente: 'Cliente',
    planta: 'Planta',
    muestreador: 'Muestreador',
    fecha_creacion: 'Fecha de creación',
    fecha_programada: 'Fecha programada',
    estado: 'Estado',
    detalle: 'Detalle',
    encargado: 'Encargado local',
    fecha_presunta: 'Fecha presunta',
    dir: 'Dirección',
    materia_prima: 'Materia prima',
    f_elab: 'Fecha elaboración',
    f_ven: 'Fecha vencimiento',
    parametros: 'Parámetros',
    opciones: 'Opciones',

    // modalensayo
    title_ensayo: 'ANÁLISIS A REALIZAR',
    muestra: 'Muestra',
    n_ensayo: 'Nombre ensayo',
    desc: 'Descripción',
    lote: 'Lote',
    revisar: 'Revisar',

    // modaledit
    title_edit: 'Editar muestra',

    // coordinacion
    coo: 'Lista de solicitudes',
    ordenar: 'Ordenar por',
    total_soli: 'TOTAL DE SOLICITUDES',
    hoy: 'Hoy',
    dia: 'Día',
    semana: 'Semana',
    mes: 'Mes',
    dias4: '4 días',

    // MODAL COORDINACION
    crear_evento: 'Crear evento',
    asunto: 'Asunto',
    resumen_solicitado: 'Resumen solicitado',
    direccion: 'Dirección',
    resumen_muestra: 'Resumen de muestra',
    cancelar: 'Cancelar',
    obvs: 'Observaciones',
    codigo_barras: 'Codigo de barras',
    DetallesolicitudID: 'Detalle solicitud ID',
    Detallesolicitud: 'Detalle solicitud',
    deletesoli: 'Eliminar solicitud',
    Editarsolicitud: 'Editar solicitud',
    servicio: 'Servicio',
    guardar: 'Guardar',
    Desearealizarloscambios: 'Desea realizar los cambios',
    region: 'region',
    manipuladores: 'Manipuladores',
    agua: 'Agua',
    ambientes: 'Ambientes',
    superficies: 'Superficies',
    alimentos: 'alimentos',
  },
  uploadfile: {
    ControldeVersiones: 'Control de Versiones',
    Clientes: 'Clientes',
    Todos: 'Todos los Clientes',
    Ensayo: 'Ensayo',
    versionactualizada: 'Version actualizada',
    Zipfile: 'Archivo .zip',
    Version: 'Versión',
    NombredelCarpetaprincipal: 'Nombre de la Carpeta principal',
    Nombredelarchivoprincipal: 'Nombre del archivo principal',
    Clear: 'Limpiar',
    Submit: 'Enviar',
    Historial: 'Historial',
    showall: 'Mostrar todos',
    Matrices: 'Matrices',
    Archivo: 'Archivo',
    Ruta: 'Ruta',
    RutaBackup: 'Ruta Backup',
    Activo: 'Activo',
    Creado: 'Creado',
    Cliente: 'Cliente',
    Ensayos: 'Ensayos',
  },

  continue: 'Continuar',
};

export default es;
